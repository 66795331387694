<template>
  <b-sidebar
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm()"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add User Team
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide" />
      </div>

      <!-- BODY -->
      <ValidationObserver
        #default="{ handleSubmit }"
        ref="UserTeamAddForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm()">
          <TextFieldInput
            v-model="userTeam.code"
            name="code"
            label="Code"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="userTeam.name"
            name="Name"
            label="Name"
            :rules="{ required: true }"
            trim />
          <SelectField
            v-model="userTeam.userGroupId"
            :options="userGroup"
            :rules="{ required: true }"
            name="GroupId"
            form-label="User Group ID"
            label-option="name"
            return-value="id" />
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 btn-submit"
              type="submit">
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="btn-submit"
              variant="outline-secondary"
              @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import SelectField from '@/components/Form/SelectField.vue'

export default {
  components: {
    TextFieldInput,
    SelectField
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    },
    userGroup: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      initialUserTeam: {
        code: '',
        name: '',
        userGroupId: ''
      },
      userTeam: {
        code: '',
        name: '',
        userGroupId: ''
      },
      teamData: []
    }
  },
  created () {
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      this.$emit('add-user-team', this.userTeam)
    },
    resetForm () {
      this.userTeam = { ...this.initialUserTeam }
      this.$refs.UserTeamAddForm.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-submit {
  min-width: 134px;
}
</style>
