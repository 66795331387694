<template>
  <div>
    <DeleteModal
      :is-delete-modal-active.sync="isDeleteModalActive"
      :items="deleteItem"
      @confirm-delete="confirmDelete($event)" />
    <UserTeamAdd
      ref="userTeamAdd"
      :user-group="userGroupName"
      :is-sidebar-active.sync="isSidebarActive"
      @add-user-team="addUserTeam($event)" />
    <UserTeamDetail
      :is-sidebar-active.sync="isSidebarDetailActive"
      :initial-user-team.sync="initialUserTeam"
      :selected-item.sync="selectedItem"
      :user-group-name="userGroupName"
      @update:user-team="updateUserTeam($event)"
      @delete-item="openDeleteModal($event)" />
    <UserTeamFilter
      :search-user-team.sync="filter.searchTeam"
      :search-user-group.sync="filter.searchGroup"
      :status-filter.sync="filter.statusFilter"
      @fetch-filter="fetchFilter()" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Add Team"
        @active-sidebar="activeSidebar()"
        @change="fetchFilter()" />
      <b-table
        striped
        hover
        responsive
        :items="items"
        :fields="fields"
        @sort-changed="sortFilter($event)"
        @row-clicked="rowClick($event)">
        <template #cell(code)="data">
          <span class="font-weight-semibold text-primary">#{{ data.value }}</span>
        </template>
        <template #cell(name)="data">
          <span class="font-weight-semibold">{{ data.value }}</span>
        </template>
        <template #cell(status)="data">
          <b-badge :variant="$statusValue(data.value)">
            {{ data.value | formatStatusData }}
          </b-badge>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import UserTeamAdd from '@/components/Users/Team/UserTeamAdd.vue'
import UserTeamDetail from '@/components/Users/Team/UserTeamDetail.vue'
import UserTeamFilter from '@/components/Users/Team/UserTeamFilter.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import TeamProvider from '@/resources/TeamProvider'
import UserGroupProvider from '@/resources/GroupProvider'
import TableTop from '../../components/TableTop.vue'

const UserTeamService = new TeamProvider()
const UserGroupService = new UserGroupProvider()

export default {
  components: {
    UserTeamAdd,
    UserTeamDetail,
    UserTeamFilter,
    DeleteModal,
    TableTop
  },
  data () {
    return {
      isSidebarActive: false,
      isSidebarDetailActive: false,
      isDeleteModalActive: false,
      filter: {
        searchTeam: '',
        searchGroup: '',
        statusFilter: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'code', label: 'Code', sortable: true },
        { key: 'name', label: 'Name' },
        { key: 'userGroup.name', label: 'Group', sortable: true },
        { key: 'status', label: 'Status' }
      ],
      items: [],
      selectedItem: {},
      initialUserTeam: {},
      deleteItem: {},
      userGroupName: []
    }
  },
  async created () {
    await this.getUserTeam()
    await this.getuserGroupName()
  },
  methods: {
    async getUserTeam () {
      try {
        const data = await UserTeamService.paginate(
          this.pagination.page,
          this.pagination.limit,
          this.filter.searchTeam,
          this.filter.searchGroup,
          this.filter.statusFilter
        )
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        console.error(err)
      }
    },
    async getuserGroupName () {
      try {
        const { data } = await UserGroupService.findAllGroupCode()
        this.userGroupName = data
      } catch (error) {
        console.error(error)
      }
    },
    async addUserTeam (val) {
      try {
        await UserTeamService.create(val)
        await this.getUserTeam()
        this.resetFormAndToggleSideBar(this.$refs.userTeamAdd)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Record Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 User Team has been added'
          }
        })
      } catch (err) {
        if (err && err?.statusCode === 409) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed: Data is duplicated',
              icon: 'EditIcon',
              variant: 'danger',
              text: 'code or name is duplicated'
            }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error: System Error',
              icon: 'EditIcon',
              variant: 'danger',
              text: 'Please try again later.'
            }
          })
        }
      }
    },
    async updateUserTeam (val) {
      try {
        const payload = { ...val }
        delete payload.id
        const { data } = await UserTeamService.update(val.id, payload)
        await this.getUserTeam()
        this.setInitialUserTeamAndSelectItem(data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 User Team has been updated'
          }
        })
      } catch (err) {
        if (err && err?.statusCode === 409) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed: Data is duplicated',
              icon: 'EditIcon',
              variant: 'danger',
              text: 'code or name is duplicated'
            }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error: System Error',
              icon: 'EditIcon',
              variant: 'danger',
              text: 'Please try again later.'
            }
          })
        }
      }
    },
    async confirmDelete (val) {
      try {
        this.isSidebarDetailActive = false
        await UserTeamService.softDelete(val.UserTeamId)
        await this.getUserTeam()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: `👋 User Team ${this.deleteItem.Name} has been deleted`
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    // async confirmDelete (val) {
    //   try {
    //     this.isSidebarDetailActive = false
    //     await UserTeamService.softDelete(val.UserTeamId)
    //     await this.getUserTeam()
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Deleted Successfully',
    //         icon: 'EditIcon',
    //         variant: 'success',
    //         text: `👋 User Team ${this.deleteItem.Name} has been deleted`
    //       }
    //     })
    //   } catch (error) {
    //     console.error(error)
    //   }
    // },
    // async confirmDelete (val) {
    //   try {
    //     this.isSidebarDetailActive = false
    //     await UserTeamService.softDelete(val.UserTeamId)
    //     await this.getUserTeam()
    //     this.$toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Deleted Successfully',
    //         icon: 'EditIcon',
    //         variant: 'success',
    //         text: `👋 User Team ${this.deleteItem.Name} has been deleted`
    //       }
    //     })
    //   } catch (error) {
    //     console.error(error)
    //   }
    // },
    activeSidebar () {
      this.isSidebarActive = true
    },
    rowClick (val) {
      this.setInitialUserTeamAndSelectItem(val)
      this.isSidebarDetailActive = true
    },
    setInitialUserTeamAndSelectItem (val) {
      this.selectedItem = { ...val }
      this.initialUserTeam = { ...val }
    },
    openDeleteModal (val) {
      this.deleteItem = { ...val }
      this.isDeleteModalActive = true
    },
    fetchFilter () {
      this.setPagination(1)
      this.getUserTeam()
    },
    resetFormAndToggleSideBar (ref) {
      this.isSidebarActive = !this.isSidebarActive
      if (ref && ref.resetForm()) ref.resetForm()
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getUserTeam()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs === 0 ? 0 : totalDocs || this.pagination.totalDocs
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
.card-body {
  padding: 0;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}
</style>
